import { render, staticRenderFns } from "./group-table.vue?vue&type=template&id=557ff987&"
import script from "./group-table.vue?vue&type=script&lang=js&"
export * from "./group-table.vue?vue&type=script&lang=js&"
import style0 from "./group-table.vue?vue&type=style&index=0&id=557ff987&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports