<template>
  <div class="age-group-table">
    <a-table
      :scroll="{ y: maxHeight }"
      :columns="columns"
      :data-source="rows"
      :pagination="false"
      table-layout="fixed"
      size="small">
      <template slot="footer">
        <table class="footer-table">
          <tr class="txt-black txt-font-din-medium">
            <td>{{ $t('components.labels.total') }}</td>
            <td>{{ sumBy(rows, columns[1].key) }}</td>
            <td>{{ sumBy(rows, columns[2].key) }}</td>
            <td>{{ sumBy(rows, columns[3].key) }}</td>
            <td>{{ sumBy(rows, columns[4].key) }}</td>
            <td>{{ sumBy(rows, columns[5].key) }}</td>
            <td>{{ sumBy(rows, columns[6].key) }}</td>
            <td>{{ sumBy(rows, columns[7].key) }}</td>
          </tr>
        </table>
      </template>
    </a-table>
  </div>
</template>

<script>
import sumBy from 'lodash/sumBy';

export default {
  name: 'MortalityGroupTable',
  props: {
    rows: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    maxHeight: {
      type: Number,
      required: true
    }
  },
  methods: {
    sumBy
  }
};
</script>

<style lang="scss">
.age-group-table {
  .footer-table {
    td {
      padding: 10px 8px;
    }
  }

  .ant-table-small {
    border: none;
  }

  .ant-table-footer {
    padding: 0;
    background: #f5f5f5 !important;
  }

  .ant-table-header-column {
    color: #363534;
    font-size: 13px;
    font-family: 'DINNextLTPro-Medium';
    text-transform: uppercase;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter {
    padding-bottom: 5px;
  }

  .ant-table-thead > tr > th {
    background: #fff;
  }
}
</style>
